import React from 'react';
import Layout from '../components/Layout';
import useSiteMetadata from '../hooks/use-site-metadata';
import AboutCompany from '../components/AboutCompany';
import {graphql, useStaticQuery} from "gatsby";

const AboutCompanyTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "company" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);
    return (
        <Layout title="CyberVision - About Company"
                location={location}
                description={siteSubtitle}
                socialImage={bg.nodes[0].publicURL}>
            <AboutCompany />
        </Layout>
    );
};

export default AboutCompanyTemplate
