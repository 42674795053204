export const DIGITAL_SIDE_TITLE = 'Meet us on the digital side';
export const DIGITAL_SIDE_SUBTITLE = 'CyberVision, Inc. is a global provider of software engineering, consulting, and support services with over twenty years of experience in helping businesses with their digital transformations. We offer hands-on expertise with a broad spectrum of modern technologies to ensure that you meet your business goals.'
export const DIGITAL_SIDE_CONTENT_HEADING = ['Our ', 'Benefits:']
export const DIGITAL_SIDE_CONTENT = [['Cost-effective delivery model', 'World-class engineering talent', 'Fast and custom-tailored project staffing'],
    ['Full-stack software engineering services', '20+ years of global experience', 'Go-to innovative technology partner']]
export const MAIN_CONTENT = [
    {
        title: ['Official Google', 'Cloud Partner'],
        text: ['Our key specialty is developing enterprise software to drive the best value for businesses. That’s why the Google Cloud Platform is such a common technological choice for our diverse projects. Our clients that used GCP to move their operations into the cloud never looked back. In fact, they’ve come to discover more tools in the GCP ecosystem that help them further optimize their business.',
            'Our GCP certified engineers can securely move your applications and data to the Google Cloud Platform and enable you to take advantage of its industry-leading technology stack. Our other areas of focus include Big Data, ML & AI, IoT development, DevOps services, Blockchain implementation, robotics, and embedded applications. We also offer full-scale system support services.'],
        button: 'Google Cloud migration services',
        buttonMobile: 'migration services',
        link: '/services/migration-to-google-cloud',
        picture: 'cloud-main'
    },
    {
        title: ['Experts in IT Outsourcing'],
        text: ['CyberVision is a software development and integration company with R&D facilities in the US and Ukraine. During more than twenty years in the high-tech business, CyberVision has designed and implemented a large number of innovative solutions that granted a proven competitive edge to our clients and partners. Our key success factor is our team of professionals passionate about building powerful and elegant systems which will shape the future of the technological era.',
            ' We’ve been through various engagements ranging from the extremely sophisticated, to mission critical, and deadline driven – all this accumulated experience allows us to get complex projects done within a squeezed timeframe. Our expertise in software engineering does not stop at the technical delivery aspects, but extends further to strategic planning of system scalability, maintenance, extensibility, competitive edge and market positioning, training, and documentation coverage.',
        ],
        button: 'Custom software development services',
        buttonMobile: 'development services',
        link: '/services/software-development',
        picture: 'outsourcing'
    },
    {
        title: ['Evangelists for', 'Big Data & AI Innovation'],
        text: ['We keep our hands on the pulse of cutting-edge Big Data, Artificial Intelligence, IoT, and Blockchain innovations in order to deliver future-proof solutions for our clients. For this purpose, we actively engage in partnerships and joint projects with other technology companies, open-source foundations, and start-ups.',
            'Our hands-on experience and contributions into the development of core Big Data and IoT technology allows us to successfully address the most complex or unusual challenges that modern enterprises may encounter on their digital transformation path.'
        ],
        button: 'Big Data analytics, ML & AI services',
        buttonMobile: 'Big Data, ML & AI services',
        link:'/services/big-data-analytics-ml-ai',
        picture: 'evangelists'
    },
    {
        title: ['IoT Development Company'],
        text: [
            'There are very few companies who know about IoT development as much as we do. CyberVision team were the ones who introduced the open-source Kaa IoT Platform to the public several years ago. Since then, we’ve delivered dozens of successful IoT projects for companies big and small. Our IoT expertise spans nearly all major industries, including Telecom, Healthcare, Logistics, Manufacturing, Consumer electronics, and Smart city. ',
            'Experienced with every aspect of IoT development, we offer end-to-end IoT services that include hardware selection, embedded application development, IoT platform integration, IoT solution design and development, IoT analytics, IoT security, DevOps services, user training, etc. '
        ],
        button: 'IoT development services',
        buttonMobile: 'IoT development services',
        link: '/expertise/iot-development',
        picture: 'iot'
    }
]
export const OUR_EXPERTIZE_HEADING = 'Our expertise'
export const OUR_EXPERTIZE_CONTENT_HEADING = ['We offer our comprehensive consulting, development, integration', 'and support services in the following high-tech domains:'];
export const OUR_EXPERTIZE_BLOCK_CONTENT = [
    {
        icon: 'analytics',
        title: 'Big Data analytics'
    },
    {
        icon: 'ml',
        title: ' Machine Learning'
    },
    {
        icon: 'ai',
        title: 'Artificial Intelligence'
    },
    {
        icon: 'iot',
        title: 'Internet of Things'
    },
    {
        icon: 'cloud',
        title: 'Cloud/CloudOps'
    },
    {
        icon: 'blockchain',
        title: 'Blockchain'
    },
    {
        icon: 'embedded',
        title: 'Embedded'
    },
    {
        icon: 'edge-computing',
        title: 'Edge computing'
    }
];
export const PRINCIPLES_TITLE = 'Our Principles';
export const PRINCIPLES_CONTENT = [
    {
        title: 'Clients are Priority',
        text: 'Our clients are our highest priority. We strive to establish long lasting and mutually beneficial business relationships.'
    },
    {
        title: 'Solutions are Reputation',
        text: 'Our solutions are our finest reputation. We are committed to excellence because you are only as good as your last delivered project.'
    },
    {
        title: 'People are Treasure',
        text: 'Our people are our greatest treasure. We nurture the spirit of innovation, open-mindedness and collaboration within our delivery teams.'
    }
]
