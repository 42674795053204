import React from 'react';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import styles from './AboutCompany.module.scss';
import HorizontalCard from '../HorizontalCard';
import {graphql, useStaticQuery} from 'gatsby';
import {
  DIGITAL_SIDE_CONTENT,
  DIGITAL_SIDE_CONTENT_HEADING,
  DIGITAL_SIDE_SUBTITLE,
  DIGITAL_SIDE_TITLE,
  MAIN_CONTENT, OUR_EXPERTIZE_BLOCK_CONTENT, OUR_EXPERTIZE_CONTENT_HEADING, OUR_EXPERTIZE_HEADING,
  PRINCIPLES_CONTENT,
  PRINCIPLES_TITLE,
} from '../../constants/about-company';
import CustomSoftwareDevelopment from '../CustomSoftwareDevelopment';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import Button from "@material-ui/core/Button";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ExpandingCard from "../ExpandingCard";

const AboutCompany = () => {
  const {
    ourPrincipalsIcons,
    domainsIcons,
    background,
    pagePictures
  } = useStaticQuery(graphql`
    query {
      ourPrincipalsIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutCompany" }
          relativeDirectory: { eq: "principles" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      domainsIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutCompany" }
          relativeDirectory: { eq: "domains" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      pagePictures: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutCompany" }
          extension: { eq: "svg"}
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutCompany" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  const domainsIconsMap = domainsIcons.nodes.reduce(
    (acc, i) => ({...acc, [i.name]: i.publicURL}),
    {}
  );
  const pagePicturesMap = pagePictures.nodes.reduce(
    (acc, i) => ({...acc, [i.name]: i.publicURL}),
    {}
  );

  const ourPrincipalsIconsSorted = ourPrincipalsIcons.nodes.sort(
    (a, b) => a.name - b.name
  );
  return (
    <Section backgroundColor="#F7F8FB" height="auto">
      <div className={styles['about-company']}>
        <InnerPagesUpperBanner
          text={['Company']}
          bg={background.nodes[0].publicURL}
        />
        <div className={`${styles['white']} ${styles['digital']}`}>
          <div className={styles['container']}>
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              className={styles['about-us-title']}
            >
              {DIGITAL_SIDE_TITLE}
            </Typography>
            <Typography className={styles['about-us-text']} align="center">{DIGITAL_SIDE_SUBTITLE}</Typography>
            <div className={`${styles['card']} ${styles['benefits-card']}`}>
              <div>
                <Typography variant={'h3'}>
                  {DIGITAL_SIDE_CONTENT_HEADING.map(line => (
                    <React.Fragment key={line}>
                      {line}
                      <br/>
                    </React.Fragment>
                  ))}
                </Typography>
              </div>
              {DIGITAL_SIDE_CONTENT.map((list, i) => (
                <ul key={i}>
                  {list.map((item, i) => (
                    <li key={item}>
                      <div className={styles['bullet-container']}>
                        <div className={styles['bullet']}/>
                      </div>
                      <Typography>{item}</Typography>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
        {MAIN_CONTENT.map((section, i) => (
          <div key={i}
               className={`${styles['main-content']} ${i % 2 === 0 ? styles['even'] : styles['odd']} ${section.picture === 'evangelists' ? styles['absolute'] : ''}`}>
            <div className={styles['container']}>
              <Typography variant={'h2'} color={'secondary'} align={'center'} className={styles['title-mobile']}>
                {section.title.map(line => (
                  <React.Fragment key={line}>
                    {line}
                    <br/>
                  </React.Fragment>
                ))}
              </Typography>
              <img src={pagePicturesMap[section.picture]} alt=""/>
              <div className={styles['divider']}/>
              <div className={styles['text-container']}>
                <Typography variant={'h2'} color={'secondary'} align={'left'}>
                  {section.title.map(line => (
                    <React.Fragment key={line}>
                      {line}
                      <br/>
                    </React.Fragment>
                  ))}
                </Typography>
                {section.text.map((p, i) => (
                  <Typography key={i}>
                    {p}
                  </Typography>
                ))}
                <Button color={'secondary'}
                        variant={'contained'}
                        data-label={section.button}
                        data-label-mobile={section.buttonMobile}
                        endIcon={<ArrowForward/>}
                        href={section.link}/>
              </div>
            </div>
          </div>
        ))}
        <div className={`${styles['container']} ${styles['our-expertise']}`}>
          <Typography align={'center'} variant={'h2'} color={'secondary'}>
            {OUR_EXPERTIZE_HEADING}
          </Typography>
          <div className={`${styles['domains-card']}`}>
            <div className={styles['heading-container']}>
              {OUR_EXPERTIZE_CONTENT_HEADING.map((line, ind) => (
                <Typography key={line} variant={'h4'} align={'center'}>
                  {line}
                </Typography>
              ))}
            </div>
            <div className={styles['cards-holder']}>
              {OUR_EXPERTIZE_BLOCK_CONTENT.map((card, i) => (
                <div key={card.title}>
                  <img src={domainsIconsMap[card.icon]} alt={card.icon}/>
                  <Typography align={'center'}>{card.title}</Typography>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={`${styles['white']} ${styles['principles']}`}>
          <div className={styles['container']}>
            <Typography
              variant="h2"
              color="secondary"
              align="center"
              className={styles['our-principles-title']}
            >
              {PRINCIPLES_TITLE}
            </Typography>
            <div className={styles['cards-container']}>
              {PRINCIPLES_CONTENT.map((i, ind) => (
                <React.Fragment key={i.title}>
                  <div className={styles['card-container']}>
                    <HorizontalCard
                      title={i.title}
                      text={i.text}
                      icon={ourPrincipalsIconsSorted[ind].publicURL}
                      hasBoxShadow
                      imgWidth={'72px'}
                      height={'100%'}
                    />
                  </div>
                  <div className={styles['card-container-mobile']}>
                    <ExpandingCard img={ourPrincipalsIconsSorted[ind].publicURL}
                                   smallCard>
                      {{collapsedView: (
                        <>
                          <Typography
                            variant={'h4'}
                            style={{
                              fontSize: '14px',
                              lineHeight:'24px',
                            }}
                          >
                            {i.title}
                          </Typography>
                        </>
                        ),
                        expandedView: (<div className={'expanding-card-container'}>
                          <hr
                            style={{
                              background: '#DFE4ED'
                            }}
                          />
                          {i.text}
                        </div>)}}
                    </ExpandingCard>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <CustomSoftwareDevelopment/>
      </div>
    </Section>
  );
};

export default AboutCompany;
